import React, { useEffect, useState, useRef } from 'react';
import moment from 'moment';
import { fetchPacientes } from '../../../services/firebase/pacientes';
import { getEmpresaById } from '../../../services/firebase/empresas';
import Gateways from '../../../gateways/api';
import { useToasts } from "react-toast-notifications";
import './style.css';

export const Chats = () => {
    const { addToast } = useToasts();
    const [pacientes, setPacientes] = useState([]);
    const [paciente, setPaciente] = useState({});
    const [empresa, setEmpresa] = useState({});
    const [mensaje, setMensaje] = useState({});
    const [mensajes, setMensajes] = useState([]);
    const [userLogged, setUserLogged] = useState({});
    const [loading, setLoading] = useState(false);
    const [isBlocked, setIsBlocked] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const [dateRange, setDateRange] = useState({
        from: moment().subtract(1, 'days').startOf('day').toDate(),
        to: moment().endOf('day').toDate(),
    });
    const messagesRef = useRef(null);

    useEffect(() => {
        initialize();
    }, []);

    useEffect(() => {
        const container = messagesRef.current;
        if (container) {
            container.addEventListener('scroll', handleScroll);
        }
        return () => {
            if (container) {
                container.removeEventListener('scroll', handleScroll);
            }
        };
    }, [mensajes]);

    const handleScroll = async () => {
        const { scrollTop, scrollHeight, clientHeight } = messagesRef.current;
        const positiveScrollTop = Math.abs(scrollTop);
        const threshold = 10;
        if (isBlocked) return;
        if (positiveScrollTop + clientHeight >= scrollHeight - threshold) {
            setIsBlocked(true);
            const newFrom = new Date(dateRange.from);
            newFrom.setDate(newFrom.getDate() - 1);
            setDateRange({
                from: newFrom,
                to: dateRange.to
            });
            await loadMoreMessages(paciente.telefono, empresa.telefono);
            setIsBlocked(false);
        }
    };

    const handleSearchChange = (event) => {
        setSearchTerm(event.target.value);
    };

    const filteredPacientes = pacientes.filter((paciente) => {
        const fullName = `${paciente.nombres} ${paciente.apellidos}`.toLowerCase();
        return fullName.includes(searchTerm.toLowerCase());
    });

    const initialize = async () => {
        const userLoginString = localStorage.getItem("usuario");
        const userLogin = userLoginString ? JSON.parse(userLoginString) : null;
        setUserLogged(userLogin || {});
        const data = await fetchPacientes(userLogin?.empresaId);
        setPacientes(data || []);
    };

    const onChangeSelectUser = async (user) => {
        setPaciente(user);
        const empresaData = await getEmpresaById(userLogged.empresaId);
        setEmpresa(empresaData || {});
        setMensajes([]);
        setDateRange({
            from: moment().subtract(2, 'days').startOf('day').toDate(),
            to: moment().endOf('day').toDate(),
        });
        await loadMoreMessages(user.telefono, empresaData.telefono);
    };

    const loadMoreMessages = async (phone_user, phone_business) => {
        setLoading(true);
        const params = {
            clientPhone: ensurePrefix(phone_user),
            bussinesPhone: ensurePrefix(phone_business),
            from: new Date(dateRange.from).toISOString(),
            to: new Date(dateRange.to).toISOString()
        };
        try {
            const response = await Gateways.WhatsApp.getAllConversation(params, userLogged.accessToken);
            if (response.length > 0) {
                const groupedByDay = response.reduce((acc, message) => {
                    const date = new Date(message.date).toLocaleDateString('es-ES', {
                        year: 'numeric',
                        month: '2-digit',
                        day: '2-digit'
                    });
                    if (!acc[date]) {
                        acc[date] = [];
                    }
                    acc[date].push(message);
                    return acc;
                }, {});
                const mensajesAgrupados = Object.entries(groupedByDay).map(([date, messages]) => ({
                    date,
                    messages
                }));
                setMensajes(mensajesAgrupados);
            }

        } catch (error) {
            console.error("Error loading messages:", error);
        } finally {
            setLoading(false);
        }
    };

    const sendMessage = async () => {
        try {
            const payload = {
                clients: [paciente],
                type: "text",
                content: { ...mensaje },
                bussinesId: await CheckActiveCompanyInMeta(empresa)
            };
            const { response } = await Gateways.WhatsApp.enviarMensaje(payload, userLogged.accessToken);
            if (response[0].status === "rejected") {
                addToast("Error al enviar mensaje", { appearance: "error" });
            } else {
                addToast("Mensaje enviado correctamente.", { appearance: "success" });
                await addConversation(ensurePrefix(empresa.telefono));
                setMensaje({});
            }
        } catch (error) {
            addToast("Error al enviar mensaje", { appearance: "error" });
        }
    };

    const addConversation = async (phone_business) => {
        try {
            const message = {
                from: phone_business,
                host: formatPhoneNumber(phone_business),
                to: ensurePrefix(paciente.telefono),
                body: mensaje.message,
                type: "text"
            };
            await Gateways.WhatsApp.addConsersation(message, userLogged.accessToken);
            await loadMoreMessages(paciente.telefono, empresa.telefono);
        } catch (error) {
            addToast("Error al guardar mensaje", { appearance: "error" });
        }
    };


    function formatPhoneNumber(phoneNumber) {
        const strNumber = phoneNumber.toString();
        return strNumber.replace(/^(\d{2})(\d{3})(\d{3})(\d{3})$/, '+$1 $2 $3 $4');
    }

    const CheckActiveCompanyInMeta = async (empresa) => {
        const [businessMeta] = await Promise.all([Gateways.metaWhatsAppApi.getAllBusinessRegistered()]);
        let business = businessMeta.data.find(element => ensurePrefix(element.display_phone_number) === ensurePrefix(empresa.telefono));
        return business.id;
    };

    const ensurePrefix = (phoneNumber) => {
        const cleanedNumber = phoneNumber.replace(/^\+/, '').replace(/\D/g, '');
        return cleanedNumber.startsWith('51') ? cleanedNumber : `51${cleanedNumber}`;
    };

    const formatDateAndTime = (dateTime) => {
        return moment(dateTime).format('hh:mm A');
    };

    const formatDateRelative = (dateString) => {
        const date = moment(dateString, 'DD/MM/YYYY');
        const today = moment();
        const yesterday = moment().subtract(1, 'days');
        if (date.isSame(today, 'day')) {
            return 'HOY';
        } else if (date.isSame(yesterday, 'day')) {
            return 'AYER';
        } else {
            return date.format('D/MM/YYYY');
        }
    };

    return (
        <div className="chat-container">
            <div className="sidebar">
                <div className="sidebar-header">
                    <div className="header-logo">
                        <svg xmlns="http://www.w3.org/2000/svg" width="39" height="39" viewBox="0 0 39 39"><path fill="#00E676" d="M10.7 32.8l.6.3c2.5 1.5 5.3 2.2 8.1 2.2 8.8 0 16-7.2 16-16 0-4.2-1.7-8.3-4.7-11.3s-7-4.7-11.3-4.7c-8.8 0-16 7.2-15.9 16.1 0 3 .9 5.9 2.4 8.4l.4.6-1.6 5.9 6-1.5z"></path><path fill="#FFF" d="M32.4 6.4C29 2.9 24.3 1 19.5 1 9.3 1 1.1 9.3 1.2 19.4c0 3.2.9 6.3 2.4 9.1L1 38l9.7-2.5c2.7 1.5 5.7 2.2 8.7 2.2 10.1 0 18.3-8.3 18.3-18.4 0-4.9-1.9-9.5-5.3-12.9zM19.5 34.6c-2.7 0-5.4-.7-7.7-2.1l-.6-.3-5.8 1.5L6.9 28l-.4-.6c-4.4-7.1-2.3-16.5 4.9-20.9s16.5-2.3 20.9 4.9 2.3 16.5-4.9 20.9c-2.3 1.5-5.1 2.3-7.9 2.3zm8.8-11.1l-1.1-.5s-1.6-.7-2.6-1.2c-.1 0-.2-.1-.3-.1-.3 0-.5.1-.7.2 0 0-.1.1-1.5 1.7-.1.2-.3.3-.5.3h-.1c-.1 0-.3-.1-.4-.2l-.5-.2c-1.1-.5-2.1-1.1-2.9-1.9-.2-.2-.5-.4-.7-.6-.7-.7-1.4-1.5-1.9-2.4l-.1-.2c-.1-.1-.1-.2-.2-.4 0-.2 0-.4.1-.5 0 0 .4-.5.7-.8.2-.2.3-.5.5-.7.2-.3.3-.7.2-1-.1-.5-1.3-3.2-1.6-3.8-.2-.3-.4-.4-.7-.5h-1.1c-.2 0-.4.1-.6.1l-.1.1c-.2.1-.4.3-.6.4-.2.2-.3.4-.5.6-.7.9-1.1 2-1.1 3.1 0 .8.2 1.6.5 2.3l.1.3c.9 1.9 2.1 3.6 3.7 5.1l.4.4c.3.3.6.5.8.8 2.1 1.8 4.5 3.1 7.2 3.8.3.1.7.1 1 .2h1c.5 0 1.1-.2 1.5-.4.3-.2.5-.2.7-.4l.2-.2c.2-.2.4-.3.6-.5s.4-.4.5-.6c.2-.4.3-.9.4-1.4v-.7s-.1-.1-.3-.2z"></path></svg>
                        <h3>WhatsApp</h3>
                    </div>
                </div>
                <div className="search-bar">
                    <input
                        type="text"
                        placeholder="Buscar o empezar un chat nuevo"
                        value={searchTerm}
                        onChange={handleSearchChange}
                    />
                </div>
                <ul className="user-list">
                    {filteredPacientes.map((paciente, index) => (
                        <li key={paciente.id} className="user-item" onClick={() => onChangeSelectUser(paciente)}>
                            <div className="user-avatar">U{index + 1}</div>
                            <div className="user-info">
                                <h4>{paciente.nombres} {paciente.apellidos}</h4>
                                <p>{paciente.mensaje}</p>
                            </div>
                        </li>
                    ))}
                </ul>
            </div>
            <div className="chat-window">
                {paciente.id ? (
                    <>
                        <div className="chat-header">
                            <h4>{paciente.nombres} {paciente.apellidos}</h4>
                        </div>
                        <div className="messages" ref={messagesRef}>
                            {loading && <div className="loading">Cargando más mensajes...</div>}
                            {mensajes.map((group, index) => (
                                <React.Fragment key={index}>
                                    {group.messages.map((mensaje, msgIndex) => (
                                        <div
                                            key={msgIndex}
                                            className={`message ${ensurePrefix(mensaje.from) === ensurePrefix(empresa.telefono) ? 'sent' : 'received'}`}>
                                            <p>
                                                {mensaje.message.split('\n').map((line, index) => (
                                                    <React.Fragment key={index}>
                                                        {line}
                                                        <br />
                                                    </React.Fragment>
                                                ))}
                                            </p>
                                            <span className="timestamp">{formatDateAndTime(mensaje.date)}</span>
                                        </div>
                                    ))}
                                    <span className="date-label">{formatDateRelative(group.date)}</span>
                                </React.Fragment>
                            ))}
                        </div>
                        <div className="message-input">
                            <input
                                type="text"
                                name="message"
                                value={mensaje.message || ''}
                                onChange={e => setMensaje({ ...mensaje, message: e.target.value })}
                                placeholder="Escribe un mensaje..."
                            />
                            <button onClick={sendMessage}>Enviar</button>
                        </div>
                    </>
                ) : (
                    <div className="messages">
                        <div className='center-text'>
                            <h1>Whatsapp</h1>
                            <samp>
                                Selecciona un usuario para comenzar a enviar y recibir mensajes.
                            </samp>
                        </div>
                    </div>
                )}
            </div>
        </div >
    );
};
